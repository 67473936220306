import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class SubscriptionService {
    constructor(private http: HttpClient) { }

    getPlans() {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/plans`).pipe(map(user => {
            return user;
        }));
    }

    getUserPlans() {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/get-user-plan`).pipe(map(user => {
            return user;
        }));
    }

    upgradePlan(planId) {
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/update-plan`, { 'plan_id': planId }).pipe(map(user => {
            return user;
        }));
    }
}