import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddAmenitiesModule } from './add-amenities/add-amenities.module';
import { AuthSuperadminGuard, AuthVendorGuard } from './_guards';
// canActivate: [AuthSuperadminGuard],
const routes: Routes = [
 
  // { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  // { path: 'view1', loadChildren: () => import('./image/image.module').then(m => m.ImageModule),canActivate: [AuthSuperadminGuard]},
  // { path: 'event', loadChildren: () => import('./event/event.module').then(m => m.EventModule),canActivate: [AuthSuperadminGuard]},
  // { path: 'registration', loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule),canActivate: [AuthSuperadminGuard]},
  // { path: 'institution-user', loadChildren: () => import('./institute_user/institute_user.module').then(m => m.InstituteUserModule),canActivate: [AuthSuperadminGuard]},
  // { path: 'roi-user', loadChildren: () => import('./roi_user/roi_user.module').then(m => m.RoiUserModule),canActivate: [AuthSuperadminGuard]},
  // { path: 'report-user', loadChildren: () => import('./report_user/report_user.module').then(m => m.ReportUserModule),canActivate: [AuthSuperadminGuard]},
  // { path: 'venue-user', loadChildren: () => import('./venue_user/venue_user.module').then(m => m.VenueUserModule),canActivate: [AuthSuperadminGuard]},
  // { path: 'food-user', loadChildren: () => import('./food_user/food_user.module').then(m => m.FoodUserModule),canActivate: [AuthSuperadminGuard]},
  // { path: 'pr-user', loadChildren: () => import('./pr-user/pr-user.module').then(m => m.PrUserModule),canActivate: [AuthSuperadminGuard]},

  // { path: 'view', loadChildren: () => import('./on-postal/on-postal.module').then(m => m.OnPostalModule),canActivate: [AuthSuperadminGuard]},
  // { path: 'transaction', loadChildren: () => import('./transaction/transaction.module').then(m => m.TransactionModule),canActivate: [AuthSuperadminGuard]},
  // { path: 'refund', loadChildren: () => import('./refund/refund.module').then(m => m.RefundModule),canActivate: [AuthSuperadminGuard]},
  // { path: 'seating-arrangement', loadChildren: () => import('./seating_arrangement/seating_arrangement.module').then(m => m.SeatingArrangementModule),canActivate: [AuthSuperadminGuard]},
  // { path: 'settings', loadChildren: () => import('./setting/setting.module').then(m => m.SettingModule),canActivate: [AuthSuperadminGuard]},
  // { path: 'employee_registration', loadChildren: () => import('./employee-registration/employee-registration.module').then(m => m.EmployeeRegistrationModule),canActivate: [AuthSuperadminGuard]},
  //  { path: 'postal-images', loadChildren: () => import('./postal-images/postal-images.module').then(m => m.PostalImagesModule),canActivate: [AuthSuperadminGuard]},

  // { path: 'judge-login', loadChildren: () => import('./judge-login/judge-login.module').then(m => m.JudgeLoginModule)},
  // { path: 'judgement-panel2', loadChildren: () => import('./judgement-panel2/judgement-panel2.module').then(m => m.JudgementPanel2Module) ,canActivate: [AuthSuperadminGuard]},
  // { path: 'judgement-panel4', loadChildren: () => import('./judgement-panel4/judgement-panel4.module').then(m => m.JudgementPanel4Module) ,canActivate: [AuthSuperadminGuard]},

   { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
   { path: 'dashboard', loadChildren: () => import('./new-dashboard/new-dashboard.module').then(m => m.NewDashboardModule),canActivate: [AuthSuperadminGuard]},
   { path: 'new-booking', loadChildren: () => import('./new-booking/new-booking.module').then(m => m.NewBookingModule),canActivate: [AuthSuperadminGuard]},
   
   { path: 'room-price', loadChildren: () => import('./room-price/room-price.module').then(m => m.RoomPriceModule),canActivate: [AuthSuperadminGuard]},
   { path: 'add-amenities', loadChildren: () => import('./add-amenities/add-amenities.module').then(m => m.AddAmenitiesModule),canActivate: [AuthSuperadminGuard]},
   { path: 'add-room-type', loadChildren: () => import('./add-room-type/add-room-type.module').then(m => m.AddRoomTypeModule),canActivate: [AuthSuperadminGuard]},
   { path: 'add-rate-type', loadChildren: () => import('./add-rate-type/add-rate-type.module').then(m => m.AddRateTypeModule),canActivate: [AuthSuperadminGuard]},
   { path: 'add-room-master', loadChildren: () => import('./add-room-master/add-room-master.module').then(m => m.AddRoomMasterModule),canActivate: [AuthSuperadminGuard]},
   { path: 'block-room', loadChildren: () => import('./block-room/block-room.module').then(m => m.BlockRoomModule),canActivate: [AuthSuperadminGuard]},
   { path: 'export-report', loadChildren: () => import('./export-report/export-report.module').then(m => m.ExportReportModule),canActivate: [AuthSuperadminGuard]},


  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full'
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
