import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

import {  HttpHeaders, HttpErrorResponse } from '@angular/common/http';
@Injectable()
export class HomeService {

    constructor(private http: HttpClient) { }
 

    

}
