import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
@Injectable()
export class EventService {
    constructor(private http: HttpClient) { }
    saveEvent(event_name: any,options,end_registration: any) {
        var formData = {
            'event_name': event_name,
            'end_registration': end_registration,
            'slotEvent': options
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/events/create`, formData).pipe(map(user => {
            return user;
        }));
    }

    updateEvent(id:any,event_name: any,options,end_registration: any) {
        var formData = {
            'event_name': event_name,
            'end_registration': end_registration,
            'slotEvent': options
        }
        return this.http.put<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/events/update?id=` + id, formData).pipe(map(user => {
            return user;
        }));
    }


    getEvents(page, search): Observable<any> {
        if (!search) {
            search = '';
        }
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/events?page=` + page + '&search=' + search).pipe(map(user => user));
    }

    deleteEvent(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/events/delete/` + id).pipe(map(user => {
            return user;
        }));
    }



    getSeating(id): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/seatings?slotId=` + id).pipe(map(user => user));
    }

    updateSeatOrder(id:any,sort_order) {
        var formData = new FormData();
        formData.append('sort_order', sort_order);
        return this.http.put<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/seatings/update-seats-order/` + id, formData).pipe(map(user => {
         return user;
     }));
    }

    updateTotalSeat(id:any,total_seats) {
        var formData = new FormData();
        formData.append('total_seats', total_seats);
        return this.http.put<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/seatings/update-seats/` + id, formData).pipe(map(user => {
         return user;
     }));
    }

    updateSeatAlign(id:any,total_seats) {
        var formData = new FormData();
        formData.append('seat_align', total_seats);
        return this.http.put<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/seatings/update-seat-align/` + id, formData).pipe(map(user => {
         return user;
     }));
    }

    updateInstitutioncolor(id:any,total_seats) {
        var formData = new FormData();
        formData.append('institute_color', total_seats);
        return this.http.put<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/seatings/update-seat-color/` + id, formData).pipe(map(user => {
         return user;
     }));
    }

    sendEmail(slotId,eventId) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/send-bulk-email/`+slotId+'?event='+eventId).pipe(map(user => {
         return user;
     }));
    }

 
    getSeat(id): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/seatings/get-seats/`+ id).pipe(map(user => user));
    }

    downloadleft(id): Observable<any> {
        var formData = new FormData();
        formData.append('seating', "LEFT");
        const file =this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/seatings/download-seats/`+id, formData,{ responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }

    downloadright(id): Observable<any> {
        var formData = new FormData();
        formData.append('seating', "RIGHT");
        const file =this.http.post<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/seatings/download-seats/`+id ,formData,{ responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }
    

}
